<template>
  <div class="d-flex-column align-center main">
    <el-card v-if="registered" class="registered">
      <div class="mw-700">
        <h4>Your team has been successfully registered.</h4>
        <p>
          You can invite team members to the team now by entering their emails below or access this
          page at any time from the email you received confirming the registration
        </p>
      </div>
    </el-card>
    <el-form label-position="top" class="main full-width input-underline">
      <el-card v-if="form">
        <div class="mw-400 main" :key="componentKey">
          <h3 class="mt-0">Add Team Member Emails</h3>
          <SuperInput
            v-for="i in range(1, 11)"
            :key="i"
            :field="form[`email${i}`]"
            v-model="form[`email${i}`].value"
            :errors.sync="form[`email${i}`].errors"
          />
        </div>
      </el-card>
      <div class="d-flex-column main mw-400">
        <div class="align-text-center">
          <el-button type="primary" class="full-width" @click="submit">Submit Emails</el-button>
        </div>
        <p class="align-text-center">You can add more emails after submission.</p>
      </div>
    </el-form>
  </div>
</template>

<script>
import { range } from 'lodash';
import { errorOrPass, flatten, makeForm } from '@/utils/forms';
import { emailStrategyOptional } from '@/utils/validators/strategy';
import SuperInput from '@/components/form/fields/SuperInput.vue';
import msg from '@/utils/constants/msg';

export default {
  name: 'InviteMembers',
  props: {
    teamId: Number,
    registered: Boolean,
  },
  components: {
    SuperInput,
  },
  data() {
    const emails = range(1, 11).reduce((acc, i) => {
      acc[`email${i}`] = {
        label: `Team Member Email ${i}`,
        validators: [emailStrategyOptional],
        required: false,
      };
      return acc;
    }, {});
    return {
      form: makeForm(emails),
      componentKey: 1,
      range,
    };
  },
  computed: {
    isUserAuthenticated() {
      return this.$store.getters['user/isAuthenticated'];
    },
  },
  mounted() {
    if (!this.teamId) {
      this.$router.push({ name: 'home' });
    }
  },
  methods: {
    submit() {
      this.$store.dispatch('views/triggerEvent');

      if (!errorOrPass(this.form)) {
        window.scrollTo(0, 0);
        this.$store.dispatch('views/notify', {
          msg: 'Highlighted emails are incorrect',
          type: 'warning',
          autoClose: true,
        });
        return;
      }

      const emailArray = Object.values(flatten(this.form)).filter(e => e !== undefined && e !== '');

      if (emailArray.length > 0) {
        this.$store.commit('root/LOADING', true);

        this.$http
          .post('/nrl/api/v1/portal/registration-team/invitemembers', {
            teamId: this.teamId,
            emails: emailArray,
          })
          .then(() => {
            this.$store.commit('root/LOADING', false);
            const emails = range(1, 11).reduce((acc, i) => {
              acc[`email${i}`] = {
                label: `Team Member Email ${i}`,
                validators: [emailStrategyOptional],
                required: false,
              };
              return acc;
            }, {});
            this.form = makeForm(emails);
            this.componentKey += 1;
            window.scrollTo(0, 0);
            this.$store.dispatch('views/notify', {
              msg: 'Successfully sent',
              type: 'success',
              autoClose: true,
            });
          })
          .catch((error) => {
            this.$store.commit('root/LOADING', false);
            window.scrollTo(0, 0);
            this.$store.dispatch('views/notify', {
              msg:
                error.response && error.response.data && error.response.data.message
                  ? error.response.data.message
                  : msg.error.apiError,
              type: 'warning',
              autoClose: true,
            });
          });
      } else {
        window.scrollTo(0, 0);
        this.$store.dispatch('views/notify', {
          msg: 'Please enter one or more emails',
          type: 'warning',
          autoClose: true,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.registered {
    text-align: center;

  h4 {
    font-family: $fontBold;
  }
}
</style>
